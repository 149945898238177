// deps
import Router from "@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/Router";
import Uri from "@splitfire-agency/raiden-library/dist/libraries/Uri";

export const adminBaseUri = new Uri(
  process.env.NEXT_PUBLIC_ADMIN_SCHEME,
  null,
  process.env.NEXT_PUBLIC_ADMIN_HOST,
  null,
  null,
  null,
  null,
);

export const adminBaseUriWithBasePath = new Uri(
  process.env.NEXT_PUBLIC_ADMIN_SCHEME,
  null,
  process.env.NEXT_PUBLIC_ADMIN_HOST,
  null,
  process.env.NEXT_PUBLIC_ADMIN_BASE_PATH,
  null,
  null,
);

/**
 * Build a router.
 * @param {import ("@splitfire-agency/raiden-library/dist/libraries/Uri").default} baseUri
 * @returns {import ("@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/Router").default})}
 */
function buildRouter(baseUri) {
  const adminRouter = new Router();
  adminRouter.group(baseUri, function (adminRouter) {
    // assets (previewer)
    adminRouter
      .get("/public/<filePath>")
      .setId("assets")
      .setConstraint("filePath", /.*/);

    // internal assets (nextjs)
    adminRouter
      .get(`/<filePath>`)
      .setId("internal-assets")
      .setConstraint("filePath", /.*/);

    adminRouter.get("").setId("home");

    // Login
    adminRouter.get("/login").setId("login");

    // Password recovery
    adminRouter.get("/password-recovery").setId("password-recovery");

    // Dashboard
    adminRouter.get("/dashboard").setId("dashboard");

    // Environments
    adminRouter.group("/environments", function (adminRouter) {
      adminRouter.get("").setId("@environments.viewAny");
      adminRouter.get("/create").setId("@environments.create");

      adminRouter.group("/<environmentId>", function (adminRouter) {
        adminRouter.setConstraint("environmentId", /\d+/);

        adminRouter.get("/view").setId("@environments.view");

        adminRouter.group("/update", function (adminRouter) {
          adminRouter.get("").setId("@environments.update");
          adminRouter
            .get("/records_accounts")
            .setId("@environments.updateRecordsAccounts");
        });
      });
    });

    // Amenities
    adminRouter.group("/amenities", function (adminRouter) {
      adminRouter.get("").setId("@amenities.viewAny");
    });

    // Compositions
    adminRouter.group("/compositions", function (adminRouter) {
      adminRouter.get("").setId("@compositions.viewAny");
      adminRouter.get("/create").setId("@compositions.create");

      adminRouter.group("/<compositionId>", function (adminRouter) {
        adminRouter.setConstraint("compositionId", /\d+/);

        adminRouter.get("/view").setId("@compositions.view");

        adminRouter.group("/update", function (adminRouter) {
          adminRouter.get("").setId("@compositions.update");
        });
      });
    });

    // Room pictures categories
    adminRouter.group("/pictures-categories", function (adminRouter) {
      adminRouter.get("").setId("@roomPicturesCategories.viewAny");
    });

    // Departements
    adminRouter.group("/departments", function (adminRouter) {
      adminRouter.get("").setId("@departments.viewAny");
    });

    // Regions
    adminRouter.group("/regions", function (adminRouter) {
      adminRouter.get("").setId("@regions.viewAny");
    });

    // Cities
    adminRouter.group("/cities", function (adminRouter) {
      adminRouter.get("").setId("@cities.viewAny");
      adminRouter.get("/create").setId("@cities.create");

      adminRouter.group("/<cityId>", function (adminRouter) {
        adminRouter.get("/update").setId("@cities.update");
      });
    });

    adminRouter.group("/users", function (adminRouter) {
      // Users/Admins
      adminRouter.group("/admins", function (adminRouter) {
        adminRouter.get("").setId("@usersAdmins.viewAny");

        adminRouter.get("/create").setId("@usersAdmins.create");

        adminRouter.get("/me").setId("@usersAdmins.me");

        adminRouter.group("/<usersAdminId>", function (adminRouter) {
          adminRouter.setConstraint("usersAdminId", /\d+/);

          adminRouter.get("/show").setId("@usersAdmins.view");

          adminRouter.get("/edit").setId("@usersAdmins.update");
        });
      });

      // Users/Groups
      adminRouter.group("/groups", function (adminRouter) {
        adminRouter.get("").setId("@usersGroups.viewAny");

        adminRouter.get("/create").setId("@usersGroups.create");

        adminRouter.group("/<usersGroupId>", function (adminRouter) {
          adminRouter.setConstraint("usersGroupId", /\d+/);

          adminRouter.get("/show").setId("@usersGroups.view");

          adminRouter.get("/edit").setId("@usersGroups.update");
        });
      });
    });
    // Users/Customers
    adminRouter.group("/customers", function (adminRouter) {
      adminRouter.get("").setId("@customers.viewAny");
      adminRouter.get("/export").setId("@customers.export");

      adminRouter.group("/<customerId>", function (adminRouter) {
        adminRouter.setConstraint("customerId", /\d+/);
        adminRouter.group("/view", function (adminRouter) {
          adminRouter.get("").setId("@customers.view");

          adminRouter.get("/white-label").setId("@customers.whiteLabel");
          adminRouter.get("/partner").setId("@customers.partner");

          // Customers services
          adminRouter.group("/services", function (adminRouter) {
            adminRouter.get("").setId("@customersServices.viewAny");
            adminRouter.get("/create").setId("@customersServices.create");
            adminRouter.group("/<serviceId>", function (adminRouter) {
              adminRouter.setConstraint("serviceId", /\d+/);
              adminRouter.get("/view").setId("@customersServices.view");
              adminRouter.get("/update").setId("@customersServices.update");
            });
          });

          // Customers discounts
          adminRouter.group("/discounts", function (adminRouter) {
            adminRouter.get("").setId("@customersDiscounts.viewAny");
            adminRouter.get("/create").setId("@customersDiscounts.create");
            adminRouter.group("/<discountId>", function (adminRouter) {
              adminRouter.setConstraint("discountId", /\d+/);
              adminRouter.get("/view").setId("@customersDiscounts.view");
              adminRouter.get("/update").setId("@customersDiscounts.update");
            });
          });

          // Customers documents
          adminRouter.group("/documents", function (adminRouter) {
            adminRouter.get("").setId("@customersDocuments.viewAny");
            adminRouter.get("/create").setId("@customersDocuments.create");
            adminRouter.group("/<documentId>", function (adminRouter) {
              adminRouter.setConstraint("documentId", /\d+/);
              adminRouter.get("/view").setId("@customersDocuments.view");
              adminRouter.get("/update").setId("@customersDocuments.update");
            });
          });

          // customers bank accounts
          adminRouter
            .get("/bank-accounts")
            .setId("@customersBankAccounts.viewAny");

          // customers rooms availabilities
          adminRouter
            .get("/rooms-availabilities")
            .setId("@customersRoomsAvailabilities.viewAny");
        });
      });

      // Customers/Contacts
      adminRouter.group("/contacts", (adminRouter) => {
        adminRouter.get("").setId("@customers.contacts.search");
        adminRouter.get("/create").setId("@customers.contacts.create");

        adminRouter.group("/<customerContactId>", (adminRouter) => {
          adminRouter.setConstraint("customerContactId", /\d+/);
          adminRouter.get("/show").setId("@customers.contacts.view");
          adminRouter.get("/update").setId("@customers.contacts.update");
        });

        // Customers/Contacts/Categories
        adminRouter.group("/categories", (adminRouter) => {
          adminRouter.get("").setId("@customers.contacts.categories.search");
          adminRouter
            .get("/create")
            .setId("@customers.contacts.categories.create");

          adminRouter.group("/<customerContactCategoryId>", (adminRouter) => {
            adminRouter.setConstraint("customerContactCategoryId", /\d+/);
            adminRouter
              .get("/show")
              .setId("@customers.contacts.categories.view");
            adminRouter
              .get("/update")
              .setId("@customers.contacts.categories.update");
          });

          // Customers/Contacts/Categories/Custom-attributes
          adminRouter.group("/custom-attributes", (adminRouter) => {
            adminRouter
              .get("")
              .setId("@customers.contacts.categories.custom_attributes.search");
            adminRouter
              .get("/create")
              .setId("@customers.contacts.categories.custom_attributes.create");

            adminRouter.group(
              "/<customerContactCategoryCustomAttributeId>",
              (adminRouter) => {
                adminRouter.setConstraint(
                  "customerContactCategoryCustomAttributeId",
                  /\d+/,
                );
                adminRouter
                  .get("/show")
                  .setId(
                    "@customers.contacts.categories.custom_attributes.view",
                  );
                adminRouter
                  .get("/update")
                  .setId(
                    "@customers.contacts.categories.custom_attributes.update",
                  );
              },
            );
          });
        });
      });
    });

    // iCal
    adminRouter.group("/ical", function (adminRouter) {
      adminRouter.get("").setId("@ical.viewAny");
      adminRouter.get("/create").setId("@ical.create");
      adminRouter.get("/export").setId("@ical.export");
      adminRouter.group("/<icalId>", function (adminRouter) {
        adminRouter.setConstraint("icalId", /\d+/);
        adminRouter.get("/view").setId("@ical.view");
        adminRouter.get("/update").setId("@ical.update");
      });
    });

    // Users/Bank accounts
    adminRouter.group("/bank-accounts", function (adminRouter) {
      adminRouter.get("").setId("@bankAccounts.viewAny");
      adminRouter.get("/create").setId("@bankAccounts.create");
    });

    // Invoices
    adminRouter.group("/invoices", function (adminRouter) {
      adminRouter.get("").setId("@invoices.viewAny");
      adminRouter.get("/export").setId("@invoices.export");
    });

    adminRouter.group("/posts", function (adminRouter) {
      adminRouter.group("/<postType>", function (adminRouter) {
        adminRouter.setConstraint("postType", /[a-z]+/);

        adminRouter.get("/create").setId("@posts.create");

        adminRouter.get("").setId("@posts.viewAny");

        adminRouter.group("/categories", function (adminRouter) {
          adminRouter.get("").setId("@postsCategories.viewAny");

          adminRouter.get("/create").setId("@postsCategories.create");

          adminRouter.get("/tree-edit").setId("@postsCategories.treeUpdate");

          adminRouter.group("/<postsCategoryId>", function (adminRouter) {
            adminRouter.get("/show").setId("@postsCategories.view");

            adminRouter.get("/edit").setId("@postsCategories.update");
          });
        });

        adminRouter.group("/<postId>", function (adminRouter) {
          adminRouter.setConstraint("postId", /\d+/);

          adminRouter.get("/show").setId("@posts.view");

          adminRouter.get("/edit-revision").setId("@posts.updateRevision");
        });
      });
    });

    // Countries
    adminRouter.group("/countries", function (adminRouter) {
      adminRouter.get("").setId("@countries.viewAny");
      adminRouter.get("/create").setId("@countries.create");
      adminRouter.group("/<countryId>", function (adminRouter) {
        adminRouter.setConstraint("countryId", /\d+/);
        adminRouter.get("/edit").setId("@countries.update");
      });
    });

    // Menus
    adminRouter.group("/menus", function (adminRouter) {
      adminRouter.get("").setId("@menus.viewAny");
      adminRouter.get("/create").setId("@menus.create");

      adminRouter.group("/<menuId>", function (adminRouter) {
        adminRouter.get("/view").setId("@menus.view");
        adminRouter.get("/update").setId("@menus.update");
      });
    });

    // Invoices
    adminRouter.group("/account-records-exports", function (adminRouter) {
      adminRouter.get("").setId("@accountRecordsExports.viewAny");
    });

    // Hotels
    adminRouter.group("/hotels", function (adminRouter) {
      adminRouter.get("").setId("@hotels.viewAny");
      adminRouter.get("/export").setId("@hotels.export");
      adminRouter.group("/<hotelId>", function (adminRouter) {
        adminRouter.get("/update").setId("@hotels.update");
        adminRouter.get("/view").setId("@hotels.view");
        adminRouter
          .get("/partner-cancellation-conditions")
          .setId("@hotels.partnerCancellationConditions");
        adminRouter.get("/room-compositions").setId("@hotels.roomCompositions");
      });
    });

    // Deliberations
    adminRouter.group("/deliberations", function (adminRouter) {
      adminRouter.get("").setId("@deliberations.viewAny");
      adminRouter.group("/<deliberationId>", function (adminRouter) {
        adminRouter.get("/view").setId("@deliberations.view");
        adminRouter.get("/update").setId("@deliberations.update");
      });
    });

    // Notifications
    adminRouter.group("/notifications", function (adminRouter) {
      adminRouter.get("").setId("@notifications.viewAny");
    });

    // Payments
    adminRouter.group("/payments", function (adminRouter) {
      adminRouter.get("").setId("@payments.viewAny");
      adminRouter.get("/export").setId("@payments.export");
      adminRouter.group("/remittances", function (adminRouter) {
        adminRouter.get("").setId("@paymentsRemittances.viewAny");
        adminRouter.get("/create").setId("@paymentsRemittances.create");
        adminRouter.group("/<remittanceId>", function (adminRouter) {
          adminRouter.get("/view").setId("@paymentsRemittances.view");
          adminRouter.get("/update").setId("@paymentsRemittances.update");
        });
      });
    });

    // Sepas
    adminRouter.group("/sepas", function (adminRouter) {
      adminRouter.get("").setId("@sepas.viewAny");
      adminRouter.get("/export").setId("@sepas.export");
      adminRouter.group("/<sepaId>", function (adminRouter) {
        adminRouter.get("/view").setId("@sepas.view");
      });
    });

    // Partners
    adminRouter.group("/partners", function (adminRouter) {
      adminRouter.get("").setId("@partners.viewAny");
      adminRouter.group("/<partnerId>", function (adminRouter) {
        adminRouter.get("/update").setId("@partners.update");
      });
    });

    // Metrics
    adminRouter.group("/metrics", function (adminRouter) {
      adminRouter.get("").setId("@metrics.viewAny");
    });

    // Remotes export
    adminRouter.group("/remotes-export", function (adminRouter) {
      adminRouter.get("").setId("@remotes.export");
    });

    // Export remote Url
    adminRouter.group("/export-remote-url", function (adminRouter) {
      adminRouter.get("").setId("@exportRemoteUrl.export");
    });

    // Carts
    adminRouter.group("/carts", function (adminRouter) {
      adminRouter.group("/bookings", function (adminRouter) {
        adminRouter.get("").setId("@cartsBookings.viewAny");

        // Note : We keep the old booking accessible through the legacy route
        adminRouter.group("/legacy", function (adminRouter) {
          adminRouter.get("").setId("@cartsBookingsLegacy.viewAny");
        });

        // Carts booking issues
        adminRouter.group("/issues", function (adminRouter) {
          adminRouter.get("").setId("@cartsBookingsIssues.viewAny");
        });

        // Carts booking review
        adminRouter.group("/reviews", function (adminRouter) {
          adminRouter.get("").setId("@cartsBookingsReviews.viewAny");
        });
      });

      adminRouter.group("/<cartId>", function (adminRouter) {
        adminRouter.get("").setId("@carts.view");
      });
    });

    // Bookings
    adminRouter.group("/bookings", function (adminRouter) {
      adminRouter.get("/export").setId("@bookings.export");
    });

    // Redirections
    adminRouter.group("/redirections/urls", (adminRouter) => {
      adminRouter.get("").setId("@admin.redirections.urls.viewAny");
      adminRouter.get("/create").setId("@admin.redirections.urls.create");

      adminRouter.group("/<redirectionUrlId>", (adminRouter) => {
        adminRouter.setConstraint("redirectionUrlId", /\d+/);
        adminRouter.get("/show").setId("@admin.redirections.urls.view");
        adminRouter.get("/update").setId("@admin.redirections.urls.update");
      });
    });

    // Rooms
    adminRouter.group("/rooms", function (adminRouter) {
      adminRouter.get("").setId("@rooms.viewAny");
      adminRouter.get("/export").setId("@rooms.export");
      adminRouter.group("/<roomId>", function (adminRouter) {
        adminRouter.setConstraint("roomId", /\d+/);
        adminRouter.group("/view", function (adminRouter) {
          adminRouter.get("").setId("@rooms.view");

          adminRouter.get("/fiscal").setId("@roomFiscal.update");

          // Rooms services (from customers/services) integrated into rooms
          adminRouter.group("/services", function (adminRouter) {
            adminRouter.get("").setId("@roomsServices.viewAny");
            adminRouter.get("/create").setId("@roomsServices.create");
            adminRouter.group("/<serviceId>", function (adminRouter) {
              adminRouter.setConstraint("serviceId", /\d+/);
              adminRouter.get("/view").setId("@roomsServices.view");
              adminRouter.get("/update").setId("@roomsServices.update");
            });
          });

          // Rooms discounts (from customers/discounts) integrated into rooms
          adminRouter.group("/discounts", function (adminRouter) {
            adminRouter.get("").setId("@roomsDiscounts.viewAny");
            adminRouter.get("/create").setId("@roomsDiscounts.create");
            adminRouter.group("/<discountId>", function (adminRouter) {
              adminRouter.setConstraint("discountId", /\d+/);
              adminRouter.get("/view").setId("@roomsDiscounts.view");
              adminRouter.get("/update").setId("@roomsDiscounts.update");
            });
          });

          // Rooms documents (from customers/documents) integrated into rooms
          adminRouter.group("/documents", function (adminRouter) {
            adminRouter.get("").setId("@roomsDocuments.viewAny");
            adminRouter.get("/create").setId("@roomsDocuments.create");
            adminRouter.group("/<documentId>", function (adminRouter) {
              adminRouter.setConstraint("documentId", /\d+/);
              adminRouter.get("/view").setId("@roomsDocuments.view");
              adminRouter.get("/update").setId("@roomsDocuments.update");
            });
          });

          // Rooms seasons (Tarifs)
          adminRouter.group("/seasons", function (adminRouter) {
            adminRouter.get("").setId("@roomsSeasons.viewAny");
            adminRouter.get("/create").setId("@roomsSeasons.create");
            adminRouter.get("/order").setId("@roomsSeasons.order");
            adminRouter.group("/<seasonId>", function (adminRouter) {
              adminRouter.setConstraint("seasonId", /\d+/);
              adminRouter.get("/view").setId("@roomsSeasons.view");
              adminRouter.get("/update").setId("@roomsSeasons.update");
            });
          });

          // Rooms ICal Export
          adminRouter.get("/exportical").setId("@roomsIcalExport.export");
          // Rooms Widget Export
          adminRouter.get("/widget").setId("@roomsWidget.export");

          adminRouter.group("/<rankingType>", function (adminRouter) {
            adminRouter.setConstraint("rankingType", /[a-z]+/);

            adminRouter.get("/ranking").setId("@roomsRanking.viewAny");
          });

          adminRouter.get("/composition").setId("@roomsComposition.viewAny");

          adminRouter.group("/importical", function (adminRouter) {
            adminRouter.get("").setId("@roomsAvailabilitiesRemotes.viewAny");
            adminRouter
              .get("/create")
              .setId("@roomsAvailabilitiesRemotes.create");
          });

          // Rooms booking review
          adminRouter.get("/reviews").setId("@roomsBookingsReviews.viewAny");

          // Rooms availabilities
          adminRouter.group("/availabilities", function (adminRouter) {
            adminRouter.get("/update").setId("@roomsAvailabilities.update");
          });
        });
      });
    });

    // Room
    adminRouter.group("/room", function (adminRouter) {
      adminRouter.group("/list", function (adminRouter) {
        adminRouter.group("/hotelId", function (adminRouter) {
          adminRouter.group("/<hotelId>", function (adminRouter) {
            adminRouter.get("").setId("@room.update");
          });
        });
      });
    });

    // Insurances
    adminRouter.group("/insurances", function (adminRouter) {
      adminRouter.get("").setId("@insurances.viewAny");
      adminRouter.get("/export").setId("@insurances.export");
    });

    // Health
    adminRouter.group("/health/status", function (adminRouter) {
      adminRouter.get("").setId("@healthStatus.view");
    });

    // Health
    adminRouter.group("/health/status", function (adminRouter) {
      adminRouter.get("").setId("@healthStatus.view");
    });

    // Internal
    adminRouter.group("/internal", function (adminRouter) {
      adminRouter.group("/horizon", function (adminRouter) {
        adminRouter.get("").setId("@internalHorizon.view");
      });
      adminRouter.group("/telescope", function (adminRouter) {
        adminRouter.get("").setId("@internalTelescope.view");
      });
    });

    // Rentals united
    adminRouter.group("/rentals", function (adminRouter) {
      adminRouter.get("").setId("@rentals.viewAny");
    });

    // Subscriptions
    adminRouter.group("/subscriptions", function (adminRouter) {
      adminRouter.get("").setId("@subscriptions.viewAny");
      adminRouter.get("/create").setId("@subscriptions.create");
      adminRouter.group("/<subscriptionId>", function (adminRouter) {
        adminRouter.get("/view").setId("@subscriptions.view");
        adminRouter.get("/update").setId("@subscriptions.update");
      });
      adminRouter.group("/customers", function (adminRouter) {
        adminRouter.get("").setId("@subscriptionsCustomers.viewAny");
        adminRouter.get("/create").setId("@subscriptionsCustomers.create");
        adminRouter.group("/<subscriptionCustomerId>", function (adminRouter) {
          adminRouter.get("/view").setId("@subscriptionsCustomers.view");
          adminRouter.get("/update").setId("@subscriptionsCustomers.update");
        });
        adminRouter.get("/export").setId("@subscriptionsCustomers.export");
      });
    });

    // Slideshows
    adminRouter.group("/slideshows", function (adminRouter) {
      adminRouter.get("").setId("@slideshows.viewAny");
      adminRouter.group("/<slideshowId>", function (adminRouter) {
        adminRouter.get("/view").setId("@slideshows.view");
      });
    });

    // Campaigns
    adminRouter.group("/campaigns", function (adminRouter) {
      adminRouter.group("/<campaignType>", function (adminRouter) {
        adminRouter.setConstraint("campaignType", /[a-z]+/);

        adminRouter.get("/create").setId("@campaigns.create");

        adminRouter.get("").setId("@campaigns.viewAny");

        adminRouter.group("/categories", function (adminRouter) {
          adminRouter.get("").setId("@campaignsCategories.viewAny");
        });
      });
    });

    // Highlights
    adminRouter.group("/highlights", function (adminRouter) {
      adminRouter.get("").setId("@highlights.viewAny");

      adminRouter.group("/<highlightId>", function (adminRouter) {
        adminRouter.get("/view").setId("@highlights.view");
      });
    });

    // Api
    adminRouter.group("/api", function (adminRouter) {
      // Pampa
      adminRouter
        .get("/resize<slug>")
        .setId("pampa")
        .setConstraint("slug", /.*/);
    });
  });
  return adminRouter;
}

export const adminRouter = buildRouter(adminBaseUri);

export const adminRouterWithBasePath = buildRouter(adminBaseUriWithBasePath);
