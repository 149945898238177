import { getAuthorizedEnvironments } from "@raiden/library-ui/helpers/authorizations/getAuthorizedEnvironments";
import useCanPerform from "@raiden/library-ui/hooks/useCanPerform";
import { useConfiguration } from "@raiden/library-ui/hooks/useConfiguration";
import { forwardRef } from "react";
import { useGuards } from "../../contexts/Guards";
import EnvironmentSelect from "../EnvironmentSelect";

const EnvironmentSelectWithAutorizations = forwardRef(
  /**
   * @typedef Props
   * @property {string} name
   * @property {number | ""} value
   * @property {string} [placeholder]
   * @property {object} [label]
   * @property {string | Array<string> | null} [authorizations]
   * @property {boolean} [includeWildcard]
   * @property {string} [labelWildcard]
   * @property {boolean} [isMultiple]
   * @property {(data) => void} onChange
   * @property {boolean} [isDisabled]
   */

  /**
   * @param {Props & import("../EnvironmentSelect").Props} props
   * @returns {import("react").FunctionComponentElement<Props> | null}
   */
  function EnvironmentSelectWithAutorizations(props, ref) {
    const { authorizations, includeWildcard, ...otherProps } = props;

    const {
      configuration: { environments },
    } = useConfiguration();

    const canPerform = useCanPerform();
    const { environmentListRelyOnAuthorizations, hasWidlcard } = useGuards();

    const localEnvironments = authorizations
      ? getAuthorizedEnvironments({
          environments: environments ?? [],
          authorizations,
          canPerform,
        })
      : environmentListRelyOnAuthorizations;

    if (localEnvironments?.length === 0) {
      return null;
    }

    return (
      <EnvironmentSelect
        environments={localEnvironments}
        forwardedRef={ref}
        includeWildcard={includeWildcard && hasWidlcard}
        {...otherProps}
      />
    );
  },
);

export default EnvironmentSelectWithAutorizations;
